import React from 'react';

// Layout imports
import Layout from '../layouts/Default/Default.jsx';
import SEO from '../layouts/SEO/SEO.jsx';

// Component imports
import Introduction from '../components/shared/Introduction/Introduction.jsx';
import PageSelector from '../components/shared/PageSelector/PageSelector.jsx';
import Promotion from '../components/shared/Promotion/Promotion.jsx';
import ReportDownload from '../components/ReportDownload/ReportDownload.jsx';
import ContentListing from '../components/shared/ContentListing/ContentListing.jsx';

// Asset imports
import Me from '../assets/images/content/me.svg';

const Accessibility = () => (
  <Layout>
    <SEO title='Web Accessibility Consultation' />

    <Introduction
      heading='Web Accessibility'
      subheading='Consultation'
      description='Providing experienced and high-quality consultation on accessibility implementation, solutions for your accessibility concerns and a specialist understanding in web accessibility requirements – ensuring your application complies with current web standards (WCAG 2.1 AA).'
      imageSource={Me}
    />

    <PageSelector
      id='mobile-nav-selector'
      triggerText='Accessibility'
    />

    <Promotion
      heading='Make your website accessible to a broader audience'
      description='It is essential, and in many situations required by law, that your web applications are accessible, to provide equal access and equal opportunity to people with diverse abilities. Let us ensure that your website provides the best user experience for everyone.'
      list={[
        {
          icon: '/images/icons/accessibility.svg',
          text: 'WCAG Compliance',
        },
        {
          icon: '/images/icons/user-check.svg',
          text: 'User Experience',
        },
        {
          icon: '/images/icons/hands.svg',
          text: 'Inclusive design',
        }
      ]}
    />

    <ReportDownload />

    <ContentListing
      heading='Why web accessibility matters'
      description='Removing the barriers that exclude people from using your products and services improves overall user experience and satisfaction, and will enable you to create higher-quality websites for all your users.'
      list={[
        {
          icon: '/images/icons/people.svg',
          title: 'Improving experiences',
          text: 'Making a website accessible means that every page is designed and coded to make it as usable, understandable, and navigational as possible to users with disabilities. Creating products that meet the needs of as many people as possible improves experience for all users, even those without additional considerations.',
        },
        {
          icon: '/images/icons/networking.svg',
          title: 'User engagement',
          text: 'Practising good web accessibility enables a larger audience to use your website, increasing your potential number of users and their ability to engage with your brand. Implementing good accessibility usually better structures your website and content, often improving SEO as a side-effect.',
        },
        {
          icon: '/images/icons/gavel.svg',
          title: 'Legal requirements',
          text: 'In an increasing number of situations, certain websites and applications are being included under recent government legislation, requiring them to meet WCAG AA Standard (2.1) as a minimum. The Disability Discrimination Act (DDA) is now more directly applicable to websites and services.',
        }
      ]}
    />

  </Layout>
)

export default Accessibility;