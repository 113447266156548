import React from 'react';

// Style imports
import './ReportDownload.scss';

// Asset imports
import Report from '../../assets/images/content/report.jpg';
import AccessibilityIcon from '../../assets/images/icons/accessibility-white.svg';
import MagnifierIcon from '../../assets/images/icons/magnifier.svg';
import TasksIcon from '../../assets/images/icons/tasks.svg';

const ReportDownload = () => {
    return (
        <section className='report-download'>
            <div className='report-download__container container'>

                <div className='report-download__content'>
                    <h2 className='report-download__heading'>Website Accessibility Reporting</h2>
                    <p className='report-download__description'>Although there are numerous online tools to help with evaluation, no tool alone can determine if a website meets accessibility guidelines. Knowledgeable human evaluation is always required. Download an example report to see how I can help you audit your application.</p>
                    <a className='button button--hover-outline report-download__download report-download__download--desktop' href='/documents/Website_Accessibility_Report_DEMO.pdf' download='Website_Accessibility_Report_DEMO.pdf'>
                        Download example
                        <span className='accessible'>
                            <abbr title="Adobe Portable Document Format">PDF</abbr>,
                            363<abbr title="Kilobyte">KB</abbr>
                        </span>
                    </a>
                </div>
                
                
                <div className='report-download__image-wrapper'>
                    <img className='report-download__image' src={Report} alt='' />
                </div>

                <div className='report-download__content'>
                    <ul className='report-download__list'>
                        <li className='report-download__list-item'>
                            <div className='report-download__icon-wrapper'>
                                <img className='report-download__icon' src={AccessibilityIcon} alt='' aria-hidden='true' />
                            </div>

                            <div className='report-download__list-content'>
                                <h3 className='report-download__subheading'>Audit your website</h3>
                                <p className='report-download__text'>All websites have different accessibility requirements. Bespoke reporting for your web application.</p>
                            </div>
                        </li>

                        <li className='report-download__list-item'>
                            <div className='report-download__icon-wrapper'>
                                <img className='report-download__icon' src={MagnifierIcon} alt='' aria-hidden='true' />
                            </div>

                            <div className='report-download__list-content'>
                                <h3 className='report-download__subheading'>Identify issues</h3>
                                <p className='report-download__text'>Evaluate accessibility throughout the development process to identify accessibility issues early, when it is easier to address them.</p>
                            </div>
                        </li>

                        <li className='report-download__list-item'>
                            <div className='report-download__icon-wrapper'>
                                <img className='report-download__icon' src={TasksIcon} alt='' aria-hidden='true' />
                            </div>

                            <div className='report-download__list-content'>
                                <h3 className='report-download__subheading'>Comply with WCAG AA 2.1</h3>
                                <p className='report-download__text'>Confidently comply with WCAG accessibility standards. Some accessibility solutions are complex and take more knowledge to implement.</p>
                            </div>
                        </li>
                    </ul>

                    <a className='button button--hover-outline report-download__download report-download__download--mobile' href='/documents/Website_Accessibility_Report_DEMO.pdf' download='Website_Accessibility_Report_DEMO.pdf'>
                        Download example
                        <span className='accessible'>
                            <abbr title="Adobe Portable Document Format">PDF</abbr>,
                            363<abbr title="Kilobyte">KB</abbr>
                        </span>
                    </a>
                </div>
            </div>
        </section>
    );
}

export default ReportDownload;